import React from 'react';
import moment from 'moment';
import DataField from '../dataField/DataField';
import { integerValue } from '../utils';
import './List.css';


const Table = props => (
  <table>
    <thead>
      <tr>
        {
          props.header.map((headerPart, index) => (
            <th key={headerPart + '-' + index}>
              <a className="list-table-header" onClick={props.sort.bind(null, index)}>
                {headerPart}
              </a>
            </th>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {
        props.data.reverse()
          .sort((a, b) => {
            let sortBy = props.sortBy;

            if (sortBy == null) return;

            const specialIndex = sortBy.indexOf('#');

            if (specialIndex !== -1) {
              sortBy = sortBy.substring(0, specialIndex)
            }

            let aValue = props.findData(sortBy, a) || '';
            let bValue = props.findData(sortBy, b) || '';

            if (props.sortReverse) {
              [aValue, bValue] = [bValue, aValue];
            }

            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
          })
          .slice(0 + (props.page - 1) * props.many, props.many + (props.page - 1) * props.many)
          .map((dataPart, index) => {
            let row = [];

            for (let i = 0; i < props.fields.length; i++) {
              let key = props.fields[i];

              if (key.includes('button#')) {
                const secondSymbolIndex = key.lastIndexOf('#');
                const name = key.substring(7, secondSymbolIndex); // 7 index is after # symbol
                const link = props.buildLink(key.substring(secondSymbolIndex + 1), dataPart);

                row.push(
                  <td key={link}>
                    <a href={link}>
                      <button className='button-outline center-button' type="button">
                        {name}
                      </button>
                    </a>
                  </td>
                );

                continue;
              }

              if (key.includes('button|')) {
                const secondSymbolIndex = key.lastIndexOf('|');
                const name = key.substring(7, secondSymbolIndex); // 7 index is after | symbol
                const functionIndex = integerValue(key.substring(secondSymbolIndex + 1, key.lastIndexOf('#')), 0);
                const parameterData = key.substring(key.lastIndexOf('#') + 1)
                let parameter;

                if (parameterData === 'this') {
                  parameter = dataPart;
                }
                else {
                  parameter = props.findData(parameterData, dataPart);
                }

                if (parameter == null) {
                  row.push(
                    <td key={name + index}>
                    </td>
                  );
                  continue;
                }

                row.push(
                  <td key={name + index}>
                      <button className='button-outline center-button' onClick={props.functions[functionIndex].bind(null, parameter)}>
                        {name}
                      </button>
                  </td>
                );

                continue;
              }

              let data;
              let format = '';

              const specialIndex = key.indexOf('#');

              if (specialIndex !== -1) {
                format = key.substring(specialIndex + 1);
                key = key.substring(0, specialIndex)
              }

              if (key.includes('.')) {
                data = props.findData(key, dataPart);
              }
              else {
                data = dataPart.get(key);
              }

              let value = '';

              if (data != null && data.count) {
                data.map((field) => {
                  value += field + ', ';
                });

                value = value.slice(0, -2);
              }
              else {
                if (format === 'time') {
                  value = moment(data).format('DD.MM.YYYY HH:mm:ss');
                }
                else if (format === 'round') {
                  value = Math.round(data *10000)/10000;
                }
                else if (format === 'integer') {
                  value = Math.round(data);
                }
                else if (data != null) {
                  value = data.toString();
                }
              }
              row.push(<td key={key + index}>{value || '-'}</td>);
            }

            return (
              <tr key={dataPart.get('id')} className={dataPart.get('id') === props.specific ? 'specific' : ''}>
                {row}
              </tr>
            );
          })
      }
    </tbody>
  </table>
);


const DataFields = props => (
  <div>
    {
      props.data.reverse().slice(0 + (props.page - 1) * props.many, props.many + (props.page - 1) * props.many)
        .map((dataPart, index) => {
          let header = '';
          let data = [];
          let buttons = [];

          for (let headerPart in props.header) {
            const value = props.findData(props.header[headerPart], dataPart) || '-';
            header += headerPart + ': ' + value + ' ';
          }

          for (let field in props.fields) {
            if (field.includes('button#')) {
              const name = field.substring(7); // 7 index is after # symbol
              const link = props.buildLink(props.fields[field], dataPart);

              buttons.push(
                <a key={link} href={link}>
                  <button className="button-outline">
                    {name}
                  </button>
                </a>
              );

              continue;
            }

            let key = props.fields[field];
            let value;
            let search;
            const specialIndex = key.indexOf('|');

            if (specialIndex !== -1) {
              search = key.substring(specialIndex + 1);
              value = props.findData(key.substring(0, specialIndex), dataPart);
            }
            else {
              value = props.findData(key, dataPart);
            }

            if (value == null) {
              continue;
            }

            if (value.count) {
              let fieldData = '';

              if (search) {
                value.map((field) => {
                  fieldData +=  field.get(search) + ', ';
                });
              }
              else {
                value.map((field) => {
                  fieldData += field + ', ';
                });
              }

              fieldData = fieldData.slice(0, -2);

              if (fieldData === '') {
                fieldData = '-';
              }

              data[field] = fieldData;
              continue;
            }

            data[field] = value;
          }

          return (
            <div key={'dataField' + index} className='datafield-list-part'>
              <DataField header={header} data={data} key={dataPart.get('id')}
                         className={dataPart.get('id') === props.specific ? 'specific' : ''}>
                <br/>
                <div>
                  {buttons}
                </div>
              </DataField>
            </div>
          );
        })
    }
  </div>
);


const PageSelection = props => {
  const pageCount = Math.ceil(props.count / props.countPerPage);

  let links = [];

  if (pageCount > 1) {
    const currentPage = props.page;

    let minPage = currentPage - 5;
    let maxPage = currentPage + 5;

    if (minPage < 1) {
      minPage = 1;
    }

    if (maxPage > pageCount) {
      maxPage = pageCount;
    }

    if (minPage !== 1) {
      links.push(
        <span key={1} className='link' onClick={props.changePage.bind(this, 1)}>
          1
        </span>
      );
      if (currentPage !== 7) {
        links.push(
          <span key='begin-dots' className='dots'>
            ...
          </span>
        );
      }
    }

    for (let i = minPage; i <= maxPage; i++) {
      if (currentPage === i) {
        links.push(<span key={i} className='link selected'>{i}</span>);
      }
      else {
        links.push(<span key={i} className='link' onClick={props.changePage.bind(this, i)}>{i}</span>);
      }
    }

    if (maxPage !== pageCount) {
      if (currentPage !== pageCount - 6) {
        links.push(
          <span key='end-dots' className='dots'>
            ...
          </span>
        );
      }
      links.push(
        <span key={pageCount} className='link' onClick={props.changePage.bind(this, pageCount)}>
          {pageCount}
        </span>
      );
    }
  }

  return (
    <p className='page-selection'>
      { props.page !== 1 ?
        <span className='page-navigation-arrow fa fa-caret-left' onClick={props.changePage.bind(this, props.page - 1)} />
        :
        null
      }
      {links}
      { props.page !== pageCount ?
        <span className='page-navigation-arrow fa fa-caret-right' onClick={props.changePage.bind(this, props.page + 1)} />
        :
        null
      }
    </p>
  );
}

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      count: 10,
      sortBy: null,
      sortReverse: false,
    };

    this.changePage = this.changePage.bind(this);
    this.setCount = this.setCount.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data != null && this.props.data.size !== nextProps.data.size) {
      this.setState({ page: 1 });
    }

    if (this.props.many === nextProps.many) return;

    this.setState({
      page: 1,
      count: this.props.many || 10
    });
  }

  changePage(toPage) {
    this.setState({ page: toPage });
  }

  setCount(event) {
    this.setState({
      count: integerValue(event.target.value, 10),
      page: 1
    });
  }

  sortBy(index) {
    if (this.state.sortBy === this.props.fields[index]) {
      this.setState({
        sortReverse: !this.state.sortReverse
      });
      return;
    }
    this.setState({
      sortBy: this.props.fields[index],
      sortReverse: false
    });
  }

  buildLink(link, data) {
    let buildedLink = link;
    let startIndex = buildedLink.indexOf('{');

    while (startIndex !== -1) {
      const original = buildedLink.substring(startIndex, buildedLink.indexOf('}') + 1);
      const replacement = this.findData(buildedLink.substring(startIndex + 1, buildedLink.indexOf('}')), data);
      buildedLink = buildedLink.replace(original, replacement);
      startIndex = buildedLink.indexOf('{');
    }

    return buildedLink;
  }

  findData(key, currentData) {
    const index = key.indexOf('.');
    let start = key;
    let end = '';

    if (index !== -1) {
      start = start.substring(0, index);
      end = key.substring(index + 1);
    }

    let data = currentData.get(start);

    if (data == null) return null;

    if (end.length) return this.findData(end, data);

    return data;
  }

  render() {
    if (this.props.loading) return <div className='loader'></div>;
    
    const count = this.props.data.size;

    if (count === 0) return <h3 className='none-text'>{this.props.emptyText}</h3>;

    return (
      <div>
        <PageSelection count={count}
                       countPerPage={this.state.count}
                       page={this.state.page}
                       changePage={this.changePage} />
        <label id='count-select-label' htmlFor='count-select'>
          {'Määrä per sivu: '}
          <select id='count-select' value={this.state.count} onChange={this.setCount}>
              <option value='5'>5</option>
              <option value='10'>10</option>
              <option value='15'>15</option>
              <option value='20'>20</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
        </label>
        { this.props.dataField ?
          <DataFields header={this.props.header} data={this.props.data}
                      page={this.state.page} many={this.state.count}
                      fields={this.props.fields} specific={this.props.specific}
                      findData={this.findData} buildLink={this.buildLink} />
          :
          <Table header={this.props.header} data={this.props.data}
               page={this.state.page} many={this.state.count}
               fields={this.props.fields} specific={this.props.specific}
               findData={this.findData} buildLink={this.buildLink}
               sortBy={this.state.sortBy} sort={this.sortBy} sortReverse={this.state.sortReverse}
               functions={this.props.functions} />
        }
        <PageSelection count={count}
                       countPerPage={this.state.count}
                       page={this.state.page}
                       changePage={this.changePage} />
      </div>
    );
  }
};
