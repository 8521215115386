var fromJS = require('immutable').fromJS;


const initialState = fromJS({
  user: null
});


export default (state=initialState,
    action) => {
  switch (action.type) {
    case 'LOGIN': {
      return state.set('user', fromJS(action.user));
    }

    case 'LOG_OUT': {
      return state.set('user', null);
    }
    
    default: {
      return state;
    }
  }
};
