import React from 'react';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation, useParams } from 'react-router-dom';
import LoginReducer from '../../../../src/login/Reducer.js';
import MenuReducer from '../../../../src/menu/Reducer.js';
import MessageReducer from '../../../../src/message/Reducer.js';
import NoticeReducer from '../../../../src/notice/Reducer.js';
import ConfirmReducer from '../../../../src/confirm/Reducer.js';
import ContractSelectReducer from '../../../../src/contractSelect/Reducer.js';
import ConstructionSiteSelectReducer from '../../../../src/constructionSiteSelect/Reducer.js';
import ChatReducer from '../../../../src/chat/Reducer.js';
import AlertConfirmReducer from '../../../../src/alertConfirm/Reducer.js';
import TruckSelectReducer from '../../../../src/truckSelect/Reducer.js';
import Login from '../../../../src/login/Login.js';
import Logout from '../../../../src/login/Logout.js';
import MassReducer from '../mass/Reducer';
import LoadsReducer from '../loads/Reducer';

import App from './Application.js';
import MassNew from '../mass/MassNew.js';
import Trucks from '../truck/Trucks.js';
import TruckNew from '../truck/TruckNew.js';
import TruckEdit from '../truck/TruckEdit.js';

export const store = createStore(
  combineReducers({
    login: LoginReducer,
    menu: MenuReducer,
    message: MessageReducer,
    notice: NoticeReducer,
    confirm: ConfirmReducer,
    alertConfirm: AlertConfirmReducer,
    mass: MassReducer,
    constructionSiteSelect: ConstructionSiteSelectReducer,
    contractSelect: ContractSelectReducer,
    truckSelect: TruckSelectReducer,
    loads: LoadsReducer,
    chat: ChatReducer
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const AppWithLocation = ({ store }) => {
  const location = useLocation();

  return <App store={store} location={location} />;
};

const TruckEditWithParams = ({ store }) => {
  const params = useParams();

  return <TruckEdit store={store} params={params} />;
}

const Root = () => (
    <Provider store={store}>
      <span id="timer" />
      <BrowserRouter>
        <AppWithLocation store={store} />
        <Routes>
          <Route path='/login' element={<Login store={store} />} />
          <Route path='/logout' element={<Logout store={store} />} />
          <Route path='/mass' element={<MassNew store={store} />} />
          <Route path='/trucks' element={<Trucks store={store} />} />
          <Route path='/trucks/new' element={<TruckNew store={store} />} />
          <Route path='/truck/:id' element={<TruckEditWithParams store={store} />} />
          <Route path='/' element={<></>}/>
        </Routes>
      </BrowserRouter>
    </Provider>
);

export default Root;