export const showConfirm = (text, success) => ({
  type: 'SHOW_CONFIRM',
  text,
  success
});

export const hideConfirm = () => ({
  type: 'HIDE_CONFIRM'
});

export const acceptConfirm = () => ({
  type: 'ACCEPT_CONFIRM'
});
