import { fromJS } from 'immutable';

const initialState = fromJS({
  contracts: [],
  selectedContract: null,
  loaded: false
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'SELECT_CONTRACT': {
      const selectedContract = action.contract;
      let exist = true;

      if (action.contract != null) {
        exist = state.get('contracts').find(contract => contract.get('id') === selectedContract);
      }
      
      if (exist) return state.set('selectedContract', action.contract);
      return state.set('selectedContract', null);
    }

    case 'SET_CONTRACTS': {
      return state.set('contracts', fromJS(action.contracts).sort((a, b) =>
        b.get('id') - a.get('id'))).set('loaded', true);
    }

    case 'ADD_CONTRACT': {
      const existing = state.get('contracts').find(contract => contract.get('id') === action.contract.id);

      if (existing) {
        return state;
      }

      return state.set('contracts', state.get('contracts').unshift(fromJS(action.contract)));
    }

    case 'CHANGE_CONTRACT': {
      const changingIndex = state.get('contracts').findIndex(contract => contract.get('id') === action.contract.id);

      if (state.get('contracts').get(changingIndex) === fromJS(action.contract)) {
        return state;
      }

      const newContracts = state.get('contracts').set(changingIndex, fromJS(action.contract));
      return state.set('contracts', newContracts);
    }

    case 'REMOVE_CONTRACT': {
      const removableContract = state.get('contracts').findIndex(contract => contract.get('id') === action.contractId);

      if (removableContract !== -1) {
        return state.set('contracts', state.get('contracts').delete(removableContract));
      }

      return state;
    }

    default: {
      return state;
    }
  }
};