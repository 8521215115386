import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './components/application/Root';
import './index.css';
import { register } from './serviceWorkerRegistration';

const container =  document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);
register();
