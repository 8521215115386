export const clearMasses = () => ({
  type: 'CLEAR_MASSES'
});

export const addMasses = masses => ({
  type: 'ADD_MASSES',
  masses
});

export const removeMassById = mass => ({
  type: 'REMOVE_MASS',
  mass
});

export const addMass = mass => ({
  type: 'ADD_MASS',
  mass
});

export const changeMass = mass => ({
  type: 'CHANGE_MASS',
  mass
});

export const updateLastPath = (id, path) => ({
  type: 'UPDATE_LAST_PATH',
  massId: id,
  path: path,
});

export const addPathToLastMass = path => ({
  type: 'ADD_PATH_TO_LAST_MASS',
  path: path,
});

export const removeLastPath = massId => ({
  type: 'REMOVE_LAST_PATH',
  massId: massId,
});
