import React from 'react';
import './FindSelect.css';


export default class FindSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      findings: [],
      value: ''
    };

    this.clear = this.clear.bind(this);
    this.clearValue = this.clearValue.bind(this);
  }

  UNSAFE_componentWillUpdate(newProps, newState) {
    if (newState.value !== this.state.value ||
        newProps.items !== this.props.items) {
      if (newState.value === '') {
        this.clear();
        return;
      }

      const findings = newProps.items.filter(item => item[this.props.data[1]].toUpperCase()
                            .indexOf(newState.value.toUpperCase()) === 0);
      this.setState({
        findings: findings
      });
    }
  }

  changeValue(event) {
    this.setState({value: event.target.value});
  }

  clear() {
    this.setState({
      findings: []
    });
  }

  selectValue(value) {
    this.setState({
      value: value
    }, () =>this.setState({findings: []}));
  }

  clearValue() {
    this.setState({
      value: ''
    });
  }

  render() {
    return (
      <div id='truck-find-select-container'>
        <input id='registerNumber' type='text'
               value={this.state.value}
               onChange={this.changeValue.bind(this)} required/>
        { this.state.findings.length === 0 ? null : (
          <div id='truck-find-select' onClick={(e) => {e.stopPropagation();}}>
            { this.state.findings.map(item => (
                <div key={item[this.props.data[0]]} className='truck-find-select-item'
                     onClick={this.selectValue.bind(this, item[this.props.data[1]])}>
                  {item[this.props.data[1]]}
                </div>
              ))
            }
          </div>)
        }
      </div>
    );
  }
}
