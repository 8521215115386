export const selectContructionSite = constructionSite => ({
  type: 'SELECT_CONSTRUCTION_SITE',
  constructionSite
});

export const setContructionSites = constructionSites => ({
  type: 'SET_CONSTRUCTION_SITES',
  constructionSites,
});

export const addContructionSite = constructionSite => ({
  type: 'ADD_CONSTRUCTION_SITE',
  constructionSite
});

export const changeContructionSite = constructionSite => ({
  type: 'CHANGE_CONSTRUCTION_SITE',
  constructionSite
});

export const removeContructionSite = constructionSiteId => ({
  type: 'REMOVE_CONSTRUCTION_SITE',
  constructionSiteId
});
