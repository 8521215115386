import React from 'react';
import { connect } from 'react-redux';
import { addLoads, selectLoad } from './Actions';
import { showNotice } from '../../../../src/notice/Actions.js';
import { selectTruck } from '../../../../src/truckSelect/Actions.js';
import { fetch, Socket } from '../utils.js';
import './Loads.css';


class Loads extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      loading: false
    };
  }

  getLoads() {
    this.setState({
      networkError: false,
      loading: true
    });
    fetch('/coatingplantloads').then(data => {
      this.props.addLoads(data);
    }).catch(error => {
      this.setState({
        networkError: true
      });
    }).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.loads.size === 0) this.getLoads();
  }

  componentDidMount() {
    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/loads/');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        console.log(data);
        this.props.addLoads(data);
      }.bind(this);
      this.socket.onerror = function(error) {
        this.setState({
          networkError: true
        });
      }.bind(this);
    }
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
  }

  selectLoad(load) {
    this.props.selectLoad(load);
    this.props.selectTruck(null);
  }


  render(): React.Element<*> {
    if (this.state.loading) {
      return (
        <div>
          <h4>Lastatut kuormat</h4>
          <div className='loader'></div>
        </div>
      );
    }

    return (
      <div>
        <h4>Lastatut kuormat</h4>
        <div id='loads'>
          { this.props.loads.size === 0  ?
            <h5>Ei yhtään</h5>
            :
            this.props.loads.reverse().splice(0, this.props.loads.size-5).map((load) => {
              return (
                <div className={load === this.props.selectedLoad ? 'load selected' : 'load'} key={load.get('id')}
                     onClick={this.selectLoad.bind(this, load === this.props.selectedLoad ? null : load)}>
                  Rekkari: {load.get('truck')} Massa: {Math.round(load.get('total_mass') * 100) / 100}
                </div>
              );
          })}
        </div>
      </div>
    );
  }
}


export default connect(state => ({
  loads: state.loads.get('loads'),
  selectedLoad: state.loads.get('selectedLoad'),
}), { showNotice, addLoads, selectLoad, selectTruck })(Loads);
