import React from 'react';
import { connect } from 'react-redux';
import { logout } from './Actions';
import { showNotice } from '../notice/Actions';
import { showMessage } from '../message/Actions';


class Logout extends React.Component {

  UNSAFE_componentWillMount () {
    try {
      if (window.self !== window.top) {
        window.top.postMessage({ type: 'logout' }, "*");
      }
    } catch (e) {

    }
    localStorage.removeItem('login');
    this.props.logout();
    this.props.showNotice('Kirjauduit ulos', 'Success');
  }

  render () {
    return null;
  }
}


export default connect(state => ({
}), { logout, showMessage, showNotice })(Logout);
