export const selectContract = (contract) => ({
  type: 'SELECT_CONTRACT',
  contract
});

export const setContracts = (contracts) => ({
  type: 'SET_CONTRACTS',
  contracts
});

export const addContract = contract => ({
  type: 'ADD_CONTRACT',
  contract
});

export const changeContract = contract => ({
  type: 'CHANGE_CONTRACT',
  contract
});

export const removeContract = contractId => ({
  type: 'REMOVE_CONTRACT',
  contractId
});
