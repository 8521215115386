import { fromJS } from 'immutable';

const initialState = fromJS({
  messages: []
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'SET_MESSAGE': {
      return state.set('messages', state.get('messages').push(fromJS(action.message)));
    }

    case 'SET_MESSAGES': {
      return state.set('messages', fromJS(action.messages));
    }

    case 'CLEAR_MESSAGES': {
      return state.set('messages', fromJS([]));
    }

    case 'REMOVE_MESSAGE': {
      const removingMessage = state.get('messages').findIndex(message =>
        message.get('id') === action.messageId);

      if (removingMessage !== -1) {
        return state.set('messages', state.get('messages').delete(removingMessage));
      }

      return state;
    }

    default: {
      return state;
    }
  }
};