import React from 'react';
import { connect } from 'react-redux';
import {
  selectContructionSite, setContructionSites, addContructionSite,
  changeContructionSite, removeContructionSite
} from './Actions';
import { fetch, integerValue, Socket } from '../utils';
import { fromJS } from 'immutable';
import '../styles.css'


class ConstructionSiteSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.readMessage = this.readMessage.bind(this);
    this.getSites = this.getSites.bind(this);
    this.changeSite = this.changeSite.bind(this);
  }

  UNSAFE_componentWillMount () {
    if (localStorage['login'] == null) {
      return;
    }

    if (this.props.constructionSites.size === 0 && this.props.selectedContract != null) {
      this.getSites(this.props.selectedContract);
    }

    if (this.socket == null) {
      this.socket = Socket('/data/site');
      this.socket.onmessage = function (e) {
        const data = JSON.parse(e.data);

        if (data['operation'] === 'create') {
          if (this.props.selectedContract !== data.model.contract.id) {
            return;
          }
          this.props.addContructionSite(data.model);
        }
        else if (data['operation'] === 'update') {
          if (this.props.selectedContract !== data.model.contract.id) {
            return;
          }
          this.props.changeContructionSite(data.model);
        }
        else if (data['operation'] === 'delete') {
          this.props.removeContructionSite(data.model);
        }
      }.bind(this)
    }

    try {
      if (window.self === window.top) return;
    } catch (e) { }

    window.addEventListener('message', this.readMessage);
  }

  componentWillUnmount () {
    if (this.socket != null) this.socket.close();
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.selectedContract === nextProps.selectedContract) return;
    this.props.selectContructionSite(null);
    if (nextProps.selectedContract != null) {
      this.getSites(nextProps.selectedContract);
    }
  }

  readMessage (e) {
    if (e.data.type !== 'site') return;
    const value = e.data.site;
    let site = null;
    if (value != null) {
      site = this.props.constructionSites.find(s => s.get('id') === value);
    }
    this.props.selectContructionSite(site);
    localStorage['site'] = value;
  }

  changeSite (event) {
    const value = integerValue(event.target.value, null);

    let site = null;

    if (value != null) {
      site = this.props.constructionSites.find(site => site.get('id') === value);
    }

    this.props.selectContructionSite(site);
    localStorage.site = value;

    window.top.postMessage({ type: 'site', site: value }, "*");
  }

  getSites (contract) {
    this.setState({
      loading: true,
    });

    fetch('/constructionsites?contract=' + contract).then(data => {
      this.setState({
        networkError: false
      });

      if (data == null) return;

      this.props.setContructionSites(data);

      if (typeof (Storage) !== 'undefined') {
        const value = integerValue(localStorage.site, null);

        if (value == null) return;

        const site = fromJS(data).find(site => site.get('id') === value);
        this.props.selectContructionSite(site);
      }
    }).catch(() => {
      if (this.state.networkError) return;

      this.setState({
        networkError: true
      });
    }).then(() => {
      this.setState({
        loading: false
      });
    });
  }

  render () {
    if (this.state.loading) return <div className='loader'></div>;

    return (
      <div className='selector'>
        <label htmlFor='site'>{'Kohde' + (this.state.networkError ? ' (Palvelin virhe)' : '')}</label>
        <select id='site' onChange={this.changeSite}
          className={this.props.selectedConstructionSite == null ? 'required' : ''}
          value={this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('id') : ''}
          required={this.props.required} disabled={this.props.selectedContract == null ? true : false}>
          <option value=''>Valitse kohde</option>
          {
            this.props.constructionSites.map(site => (
              <option key={site.get('id')} value={site.get('id')}>
                {(site.get('custom_id') != null ? (site.get('custom_id') + ' ') : '') + site.get('name')}
              </option>
            ))
          }
        </select>
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  constructionSites: state.constructionSiteSelect.get('constructionSites'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), {
  selectContructionSite, setContructionSites, addContructionSite,
  changeContructionSite, removeContructionSite
})(ConstructionSiteSelect);
