import React from 'react';
import { connect } from 'react-redux';
import { hideConfirm, acceptConfirm } from './Actions';
import './Confirm.css';


const Confirm = props => {
    if (!props.visible) {
      return null;
    }
    return (
      <div className='modal' onClick={props.hideConfirm}>
        <div className='confirm-area' onClick={(e) => {e.stopPropagation();}}>
          <div className='row'>
            <div className='column'>
              {props.text}
            </div>
          </div>
          <div className='row'>
            <div className='column'>
              <button className='button-outline' onClick={props.hideConfirm}>
                Peruuta
              </button>
            </div>
            <div className='column'>
              <button onClick={props.acceptConfirm}>
                Kyllä
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}


export default connect(state => ({
  visible: state.confirm.get('visible'),
  text: state.confirm.get('text'),
}),{hideConfirm, acceptConfirm})(Confirm);
