import React from 'react';
import { connect } from 'react-redux';
import { hideMessage } from './Actions';
import './Message.css';


const Message = props => {
  if (!props.visible) return null;

  return (
    <div className='modal-message' onClick={props.hideMessage}>
      <div className={'message-area ' + props.type}
           onClick={(e) => {e.stopPropagation();}}>
        <button onClick={props.hideMessage}>X</button>
        <h2>{props.header}</h2>
        <span>{props.body}</span>
      </div>
    </div>
  );
}

export default connect(state => ({
  visible: state.message.get('visible'),
  header: state.message.get('header'),
  body: state.message.get('body'),
  type: state.message.get('type'),
}), { hideMessage })(Message);
