export const showMessage = (header, body, messageType) => ({
  type: 'SHOW_MESSAGE',
  header,
  body,
  messageType
});

export const hideMessage = () => ({
  type: 'HIDE_MESSAGE'
});
