var fromJS = require('immutable').fromJS;


const initialState = fromJS({
  showMenu: false,
  menuItems: []
});


export default (state=initialState,
    action) => {
  switch (action.type) {
    case 'TOGGLE_MENU': {
      return state.set('showMenu', !state.get('showMenu'));
    }
    case 'ADD_MENU_ITEMS': {
      return state.set('menuItems', action.items);
    }
    default: {
      return state;
    }
  }
};
