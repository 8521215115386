import config from './config.js';

const CORS_URL = 'https://' + config.cors;
const API_AUTH_URL = 'https://' + config.auth ;
const API_URL = 'https://' + config.api;
const WS_URL = 'wss://' + config.socket;

export const paddedNumber = number => number <= 99 ? ('0' + number).slice( - 2) : number;

export function Socket(url) {
  const socket = new WebSocket(WS_URL + url + '?x-nevia-auth=Bearer' + localStorage['login'].substring(7));
  socket.onopen = function(e) {
    console.log("SOCKET CONNECTED");
  }
  return socket;
}

export const timer = (time, data) => (
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, time); 
  })
);

export const transformErrors = errors => {
  return errors.map(error => {
    if (error.schema.messages && error.schema.messages[error.name][error.argument]) {
      return {
        error,
        message: error.schema.messages[error.name][error.argument],
      };
    }
    return error;
  });
};

export function fetch(url, method='GET', data=null, login=false) {
  if (data != null) {
    data = JSON.stringify(data);
  }

  var apiUrl = API_URL;
  
  let headers = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
	'x-nevia-auth': localStorage['login']
  };

  if (login) {
	  apiUrl = API_AUTH_URL;
	  headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Access-Control-Request-Headers': 'x-nevia-auth, Content-Type',
		'x-nevia-auth': localStorage['login']
	  };
  }

  return window.fetch(CORS_URL + '/' + apiUrl + url, {
    headers: headers,
    method: method,
    body: data,
    mode: 'cors',
  }).then(request => {
    if (!request.ok) {
      throw Error(request.status);
    }

    if (login) return request;

    if (request.status !== 204) return request.json();
    return null;
  });
}

export function integerValue(value, defaultValue) {
  const parsedValue = parseInt(value, 10);

  if (isNaN(parsedValue)) {
    return defaultValue;
  }

  return parsedValue;
}

export function floatValue(value, defaultValue) {
  try {
    const parsedValue = parseFloat(value.replace(',', '.'));

    if (isNaN(parsedValue)) return defaultValue;
    
    return parsedValue;
  } catch(err) {
    return defaultValue;
  }
}
