import React from 'react';
import { connect } from 'react-redux';
import { selectTruck, setTrucks } from './Actions';
import { showMessage } from '../message/Actions';
import { fetch, integerValue } from '../utils';


class TruckSelect extends React.Component {
  constructor(props) {
    super(props);

    this.truckSelect = null;

    this.state = {
      loading: false,
      firstTime: true
    };

    this.getTrucks = this.getTrucks.bind(this);
  }

  componentDidMount () {
    if (this.props.all) {
      this.getTrucks();
      return;
    }

    if (this.props.selectedConstructionSite != null) {
      this.getTrucks(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentDidUpdate (lastProps) {
    if (this.props.all) {
      return;
    }

    if (this.props.selectedConstructionSite == null || lastProps.selectedContract !== this.props.selectedContract) {
      this.props.setTrucks([]);
      this.props.selectTruck(null);
      return;
    }

    if (this.props.selectedConstructionSite === lastProps.selectedConstructionSite) return;

    this.getTrucks(this.props.selectedConstructionSite.get('id'));
  }

  changeTruck (event) {
    const value = integerValue(event.target.value, null);
    const truck = this.props.trucks.find(truck => truck.get('id') === value);
    this.props.selectTruck(truck);
    localStorage.truck = value;
  }

  getTrucks (site) {
    if (this.state.firstTime) {
      this.setState({
        loading: true,
        firstTime: false
      });
    }

    this.setState({ gettingTrucks: true });

    let url = '/trucks?site=' + site;

    if (this.props.all) {
      url = '/trucks/';
    }

    fetch(url)
      .then(data => {
        this.setState({
          networkError: false
        });

        if (data != null) {
          this.props.setTrucks(data);

          if (this.truckSelect) {
            this.truckSelect.click();
          }

          if (typeof (Storage) !== 'undefined') {
            const value = integerValue(localStorage.truck, null);

            if (value == null) return;

            const truck = this.props.trucks.find(truck => truck.get('id') === value);
            this.props.selectTruck(truck);
          }
        }
      })
      .catch(error => {
        this.setState({ networkError: true });
      })
      .then(data => {
        this.setState({
          loading: false,
          gettingTrucks: false
        });
      });
  }

  render () {
    if (this.state.loading) return <div className='loader'></div>;

    return (
      <div>
        {this.state.networkError ? '(Ei ajan tasainen)' : ''}
        <select id='truck' ref={element => this.truckSelect = element}
          className={this.props.required && this.props.selectedTruck == null ? 'required' : ''}
          onChange={this.changeTruck.bind(this)}
          value={this.props.selectedTruck != null && this.props.selectedTruck.get('id') || ''}
          required={this.props.required}
          disabled={!this.props.all && this.props.selectedConstructionSite == null ? true : false}
          onClick={this.props.selectedConstructionSite != null && !this.state.gettingTrucks ?
            this.getTrucks.bind(null, this.props.selectedConstructionSite.get('id')) : null}>
          <option value='' onClick={(e) => { e.stopPropagation(); }}>
            Valitse rekka
          </option>
          {
            this.props.trucks.map(truck => (
              <option key={truck.get('id')} value={truck.get('id')}
                onClick={(e) => { e.stopPropagation(); }}>
                {truck.get('register_number')}
              </option>
            ))
          }
        </select>
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  trucks: state.truckSelect.get('trucks'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
}), { selectTruck, setTrucks, showMessage })(TruckSelect);
