const MapTiles = (x, y) => {
  if (x >= 90576.65118214424 && x <= 739377.0559941401 && y <= 7779024.6602204945 && y >= 6615119.835815004) {
    return {
      url: 'https://avoin-karttakuva.maanmittauslaitos.fi/avoin/wmts/1.0.0/taustakartta/default/WGS84_Pseudo-Mercator/{z}/{y}/{x}.png?api-key=36505207-dc80-4d1e-8d0d-62ce7b6f3826',
      attribution: 'Karttamateriaali &copy; <a href="http://www.maanmittauslaitos.fi/avoindata">Maanmittauslaitos</a>',
      maxZoom: 16
    };
  }

  return {
    url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
    attribution: '<a href="https://foundation.wikimedia.org/wiki/Maps_Terms_of_Use">Wikimedia maps</a> | Map data &copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    maxZoom: 19
  };
};

export default MapTiles;
