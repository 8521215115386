import { fromJS } from 'immutable';

const initialState = fromJS({
  visible: false,
  header: '',
  body: '',
  type: ''
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'SHOW_MESSAGE': {
      return state.set('visible', true).set('header', action.header)
        .set('body', action.body).set('type', action.messageType);
    }

    case 'HIDE_MESSAGE': {
      return state.set('visible', false).set('header', '')
        .set('body', '').set('type', '');
    }

    default: {
      return state;
    }
  }
};