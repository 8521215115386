import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleMenu } from './Actions';
import { showConfirm } from '../confirm/Actions';
import { showMessage } from '../message/Actions';
import { timer } from '../utils';
import Menu from './Menu';
import { fetch } from '../utils.js';


class MenuComplete extends Component {

  constructor(props) {
    super(props);

    this.userChecker = null;
    this.currentUrl;

    this.state = {
      logOut: false,
    };

    this.confirmLogout = this.confirmLogout.bind(this);
    this.logout = this.logout.bind(this);
    this.checkUserIsValid = this.checkUserIsValid.bind(this);
  }


  componentDidMount () {
    this.userChecker = setInterval(this.checkUserIsValid, 30000);

    try {
      if (window.self !== window.top) {
        setInterval(this.checkURLchange, 3000);
      }
    } catch (e) {

    }
  }

  checkURLchange () {
    const url = window.location.href;

    if (url !== this.currentUrl) {
      this.currentUrl = url
      window.top.postMessage({
        type: 'urlChanged',
        url: url
      }
        , "*");
    }
  }

  componentWillUnmount () {
    clearInterval(this.userChecker);
  }

  confirmLogout () {
    this.props.showConfirm('Kirjaudutaanko ulos?', this.logout);
  }

  logout () {
    timer(0).then(() => {
      this.props.toggleMenu();
      this.setState({ logOut: true });
    });
  }

  checkUserIsValid () {
    fetch('/user/profile/', 'GET', null, true).then(request => {
      return;
    }).catch(error => {
      if (error.message === '403') {
        this.logout();
        this.props.showMessage('Kirjauduttu ulos', 'Kirjautuminen on vanhentunut', 'Warning');
      }
    });
  }

  render () {
    if (this.state.logOut) {
      return window.location.replace('/logout');
    }

    const currentUrl = window.location.href.substring(window.location.href.lastIndexOf('/'), window.location.href.length);

    return (
      <div>
        <Menu visible={this.props.visibleMenu} onClose={this.props.toggleMenu} organization={this.props.organization}
          user={this.props.user} app={this.app}>
          <ul>
            {
              this.props.menuItems.map(link => {
                if (link.url != null) {
                  return (
                    <li key={link.url} className={currentUrl === link.url ? 'selected' : ''}>
                      <a onClick={this.props.toggleMenu} href={link.url}>{link.header}</a>
                    </li>
                  );
                }
                return (
                  <li key={link.url} onClick={link.function}>
                    <span>{link.header}</span>
                  </li>
                );
              })
            }
            <li><a onClick={this.confirmLogout.bind(this)}><p className='text-danger'>Kirjaudu ulos</p></a></li>
          </ul>
        </Menu>
        <div className={this.props.visibleMenu ? "menu-hidden" : "menu-visible"}>
          <div id="header">
            <button onClick={this.props.toggleMenu}>
              {this.props.visibleMenu ?
                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                :
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              }
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  visibleMenu: state.menu.get('showMenu'),
  menuItems: state.menu.get('menuItems'),
  organization: state.login.get('user') ? state.login.get('user').get('organizationName') : '',
  user: state.login.get('user') ? state.login.get('user').get('firstName') + ' ' + state.login.get('user').get('lastName') : ''
}),
  { toggleMenu, showConfirm, showMessage },
)(MenuComplete)