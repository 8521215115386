import { fromJS } from 'immutable';

const initialState = fromJS({
  visible: false,
  text: '',
  type: '',
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'SHOW_NOTICE': {
      return state.set('visible', true).set('type', action.noticeType).set('text', action.text);
    }

    case 'HIDE_NOTICE': {
      return state.set('visible', false);
    }

    default: {
      return state;
    }
  }
};