import { fromJS } from 'immutable';

const initialState = fromJS({
  visible: false,
  texts: []
});

let functions = [];

export default (state=initialState,
    action) => {
  switch (action.type) {
    case 'SHOW_ALERT_CONFIRM': {
      functions.push(action.success);
      let texts = state.get('texts');
      texts = texts.push(action.text);
      return state.set('visible', true).set('texts', texts);
    }

    case 'ACCEPT_ALERT_CONFIRM': {
      const lastFunction = functions.pop();
      if (lastFunction instanceof Function) lastFunction(action.notice);

      let texts = state.get('texts');
      texts = texts.pop();

      if (texts.size === 0) {
        return state.set('visible', false).set('texts', texts);
      }

      return state.set('texts', texts);
    }

    default: {
      return state;
    }
  }
};
