import React from 'react';
import './DataField.css';


export default class DataField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleData: false,
    };

    this.toggleData = this.toggleData.bind(this);
  }

  toggleData() {
    this.setState({
      toggleData: !this.state.toggleData
    });
  }

  render() {
    return (
      <div>
        <div className={this.state.toggleData ? "data-field-top selected " + this.props.class : "data-field-top " + this.props.class}
             onClick={this.toggleData}>
          {this.props.header}
        </div>
        { this.state.toggleData ? (
          <div className={"data-field-bottom " + this.props.class}>
            {Object.keys(this.props.data).map(key => (
              <span key={key} >
                <b>{key + ': '}</b>{this.props.data[key]}
              </span>
            ))}
            <br/>
            {this.props.children}
          </div>
          ) : null }
      </div>
    );
  }
}
