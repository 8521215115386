import React from 'react';
import { connect } from 'react-redux';
import {
  selectContract, setContracts, addContract, changeContract,
  removeContract
} from './Actions';
import { fetch, integerValue, Socket } from '../utils';
import '../styles.css'


class ContractSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = { false: true };

    this.readMessage = this.readMessage.bind(this);
    this.getContracts = this.getContracts.bind(this);
    this.changeContract = this.changeContract.bind(this);
  }

  componentDidMount () {
    if (localStorage['login'] == null) {
      return;
    }

    this.getContracts();
    if (this.socket == null) {
      this.socket = Socket('/data/contract');
      this.socket.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          this.props.addContract(data.model);
        }
        else if (data['operation'] === 'update') {
          this.props.changeContract(data.model);
        }
        else if (data['operation'] === 'delete') {
          this.props.removeContract(data.model);
        }
      }.bind(this)
    }

    try {
      if (window.self === window.top) return;
    } catch (e) { }

    window.addEventListener('message', this.readMessage);
  }

  componentDidUpdate (lastProps, lastState) {
    if (this.props.year !== lastProps.year) {
      this.getContracts();
    }
  }

  componentWillUnmount () {
    if (this.socket != null) this.socket.close();
  }

  readMessage (e) {
    if (e.data.type !== 'contract') return;
    this.props.selectContract(e.data.contract);
    localStorage['contract'] = e.data.contract;
  }

  changeContract (event) {
    const value = integerValue(event.target.value, null);
    this.props.selectContract(value);
    localStorage.contract = value;
    window.top.postMessage({ type: 'contract', contract: value }, "*");
  }

  getContracts () {
    this.setState({ loading: true });

    fetch('/contracts/').then(data => {
      if (this.props.year != null) {
        data = data.filter(contract => contract.year === this.props.year);
      }

      this.props.setContracts(data);
      if (typeof (Storage) !== 'undefined') {
        const contract = integerValue(localStorage.contract, null);
        this.props.selectContract(contract);
      }
      this.setState({
        networkError: false
      });
    }).catch(error => {
      this.setState({
        networkError: true
      });
    }).finally(error => {
      this.setState({ loading: false });
    });
  }

  render () {
    if (this.state.loading) return <div className='loader'></div>;
    return (
      <div className='selector'>
        <label htmlFor='contract'>{'Urakka ' + (this.state.networkError ? ' (Palvelin virhe)' : '')}</label>
        <select id='contract'
          className={this.props.selectedContract == null ? 'required' : ''}
          onChange={this.changeContract}
          value={this.props.selectedContract || ''}
          required={this.props.required}>
          <option value=''>Valitse urakka</option>
          {
            this.props.contracts.map(contract => (
              <option key={contract.get('id')} value={contract.get('id')}>
                {contract.get('name')}
              </option>
            ))
          }
        </select>
      </div>
    );
  }
}
// function mapStateToProps (state) {
//   return {
//     contracts: state.contractSelect.get('contracts'),
//     selectedContract: state.contractSelect.get('selectedContract')
//   }
// }

// const mapDispatchToProps = {
//   selectContract, setContracts, addContract, changeContract,
//   removeContract
// }

export default connect(state => ({
  contracts: state.contractSelect.get('contracts'),
  selectedContract: state.contractSelect.get('selectedContract'),
}), {
  selectContract, setContracts, addContract, changeContract,
  removeContract
})(ContractSelect);
