export const showAlertConfirm = (text, success) => ({
  type: 'SHOW_ALERT_CONFIRM',
  text,
  success
});

export const acceptAlertConfirm = notice => ({
  type: 'ACCEPT_ALERT_CONFIRM',
  notice
});
