import React from 'react';
import './Weather.css';


export default class Weather extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreData: false,
      temperature: null,
      precipitation: null,
      humidity: null,
      icon: null,
      previousTime: null
    };

    this.toggleMoreData = this.toggleMoreData.bind(this);
    this.getWeather = this.getWeather.bind(this);
  }

  UNSAFE_componentWillMount () {
    if (this.props.latitude !== null &&
      this.props.longitude !== null) {
      this.getWeather();
      setInterval(this.getWeather, 60000);
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.latitude !== this.props.latitude &&
      prevProps.longitude !== this.props.longitude &&
      this.state.previousTime == null) {
      this.getWeather();
      setInterval(this.getWeather, 60000);
    }
  }

  toggleMoreData () {
    this.setState({
      showMoreData: !this.state.showMoreData
    });
  }

  getWeather () {
    let timeNow = new Date();

    if (this.state.previousTime != null && timeNow.getHours() === this.state.previousTime.getHours()
      && timeNow.getMinutes() < this.state.previousTime.getMinutes() + 10) {
      return;
    }

    this.setState({
      previousTime: new Date(timeNow)
    });

    timeNow.setSeconds(0, 0);
    timeNow.setMinutes(0);

    fetch(
      'https://opendata.fmi.fi/wfs?service=WFS&version=2.0.0&request=getFeature&storedquery_id=ecmwf::forecast::surface::point::timevaluepair&latlon='
      + this.props.latitude + ',' + this.props.longitude + '&starttime=' + timeNow.toISOString() + '&endtime=' + timeNow.toISOString())
      .then(response => {
        if (!response.ok) {
          console.log('Error getting weather');
          return;
        }

        response.text().then(data => {
          const parser = new DOMParser();
          const values = parser.parseFromString(data, 'text/xml').getElementsByTagName('wml2:value');
          this.setState({
            temperature: Math.round(parseFloat(values[1].innerHTML)),
            precipitation: Math.round(parseFloat(values[16].innerHTML) * 10) / 10,
            humidity: Math.round(parseFloat(values[3].innerHTML) * 10) / 10,
            icon: parseInt(values[12].innerHTML)
          });
        });
      });
  }

  render () {
    if (this.state.temperature == null || this.props.notVisible) {
      return null;
    }

    if (!this.state.showMoreData) {
      return (
        <div id='weather-overall' onClick={this.toggleMoreData}>
          <div className={'weather-symbol img' + this.state.icon}></div>
          <span>{this.state.temperature}°</span>
        </div>
      );
    }
    return (
      <div id='weather' onClick={this.toggleMoreData}>
        <div className={'weather-symbol img' + this.state.icon}></div>
        <span>Lämpötila: {this.state.temperature} °C</span>
        <br />
        <span>Sademäärä: {this.state.precipitation} mm</span>
        <br />
        <span>Kosteus: {this.state.humidity} %</span>
      </div>
    );
  }
}
