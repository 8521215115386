import { fromJS } from 'immutable';

const initialState = fromJS({
  constructionSites: [],
  selectedConstructionSite: null,
  loaded: false
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'SELECT_CONSTRUCTION_SITE': {
      return state.set('selectedConstructionSite', fromJS(action.constructionSite));
    }

    case 'SET_CONSTRUCTION_SITES': {
      return state.set('constructionSites', fromJS(action.constructionSites).sort((a, b) =>
        b.get('id') - a.get('id')).set('loaded', true));
    }

    case 'ADD_CONSTRUCTION_SITE': {
      const existing = state.get('constructionSites').find(
        constructionSite => constructionSite.get('id') === action.constructionSite.id);

      if (existing) {
        return state;
      }

      return state.set('constructionSites', state.get('constructionSites').unshift(fromJS(action.constructionSite)));
    }

    case 'CHANGE_CONSTRUCTION_SITE': {
      const changingIndex = state.get('constructionSites').findIndex(constructionSite => constructionSite.get('id') === action.constructionSite.id);

      if (state.get('constructionSites').get(changingIndex) === fromJS(action.constructionSite)) {
        return state;
      }

      const newConstructionSites = state.get('constructionSites').set(changingIndex, fromJS(action.constructionSite));
      return state.set('constructionSites', newConstructionSites);
    }

    case 'REMOVE_CONSTRUCTION_SITE': {
      const removableConstructionSite = state.get('constructionSites').findIndex(
        constructionSite => constructionSite.get('id') === action.constructionSiteId);

      if (removableConstructionSite !== -1) {
        return state.set('constructionSites', state.get('constructionSites').delete(removableConstructionSite));
      }

      return state;
    }

    default: {
      return state;
    }
  }
};
