import { fromJS } from 'immutable';

const initialState = fromJS({
  trucks: [],
  selectedTruck: null
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'SELECT_TRUCK': {
      return state.set('selectedTruck', action.truck);
    }

    case 'SET_TRUCKS': {
      return state.set('trucks', fromJS(action.trucks));
    }

    default: {
      return state;
    }
  }
};