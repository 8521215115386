import { fromJS } from 'immutable';

const initialState = fromJS({
  visible: false,
  text: ''
});

let success = null;

export default (state=initialState,
    action) => {
  switch (action.type) {
    case 'SHOW_CONFIRM': {
      success = action.success;
      return state.set('visible', true).set('text', action.text);
    }

    case 'HIDE_CONFIRM': {
      return state.set('visible', false);
    }

    case 'ACCEPT_CONFIRM': {
      if (success instanceof Function) success();
      return state.set('visible', false);
    }

    default: {
      return state;
    }
  }
};
