import React from 'react';
import { connect } from 'react-redux';
import { acceptAlertConfirm } from './Actions';
import { showNotice } from '../notice/Actions';
import './AlertConfirm.css';


class AlertConfirm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      notice: ''
    }

    this.confirm = this.confirm.bind(this);
    this.changeNotice = this.changeNotice.bind(this);
  }

  componentDidUpdate (lastProps) {
    if (this.props.text != null && this.props.text !== lastProps.text) {
      const audio = new Audio(require('./alarm.wav'));
      audio.play();
    }
  }

  changeNotice (event) {
    this.setState({ notice: event.target.value });
  }

  confirm () {
    if (this.state.notice === '') {
      this.props.showNotice('Huomio ei voi olla tyhjä', 'Warning');
      return;
    }

    this.props.acceptAlertConfirm(this.state.notice);
    this.setState({ notice: '' });
  }

  render () {
    if (!this.props.visible) {
      return null;
    }
    return (
      <div className='alert-modal'>
        <div className='alert-confirm-area'>
          {this.props.text}
          <label htmlFor='attentions'>Huomio</label>
          <input id='attentions' type='text' value={this.state.notice}
            onChange={this.changeNotice.bind(this)} />
          <button onClick={this.confirm}>
            Hyväksy
          </button>
        </div>
      </div>
    );
  }
}


export default connect(state => ({
  visible: state.alertConfirm.get('visible'),
  text: state.alertConfirm.get('texts').last(),
}), { acceptAlertConfirm, showNotice })(AlertConfirm);
