import React from 'react';
import { connect } from 'react-redux';
import { hideNotice } from './Actions';
import { timer } from '../utils';
import './Notice.css';


const Notice = props => {
  let visible = 'hidden';
  if (props.visible) {
      visible = '';
      timer(2000).then(() => { props.hideNotice() });
  }
  return (
    <div id={props.type} className={'notice-area ' + visible}>
      {props.text}
    </div>
  );
}


export default connect(state => ({
  visible: state.notice.get('visible'),
  type: state.notice.get('type'),
  text: state.notice.get('text'),
}), { hideNotice })(Notice);
