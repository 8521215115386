import React, { Component } from 'react';
import './Menu.css';


class Menu extends Component {

  constructor(props) {
    super(props);
    this.closeClicked = this.closeClicked.bind(this);
  }

  closeClicked() {
    if (this.props.onClose == null) return;
    (this).props.onClose();
  }

  render() {
    const className = this.props.visible ? "visible" : "hidden";
    const organization = this.props.organization !== '' ? this.props.organization : '';
	const user = this.props.user !== '' ? this.props.user : '';
    return (
      <div id="menu" className={className}>
        <div id="menu-close" onClick={this.closeClicked}></div>
        <div id="menu-content">
          <div id="menu-top">
            <span>{organization}</span>
            <br/>
            <span>{user}</span>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Menu;